@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import './animate.min.css';


:root {
  --font-family: 'Plus Jakarta Sans', sans-serif;
  --section-spacing: 64px;
  --heading-spacing: 32px;
  --text-white: #FFFFFF;
}

@media (max-width: 599px) {
  :root {
    --section-spacing: 50px;
    --heading-spacing: 22px;
  }
}


* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

button,
input {
  font-family: var(--body-font)
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="file" i]::-webkit-file-upload-button {
  font-family: inherit;
}

img {
  height: 100%;
  width: 100%;
}

img,
video {
  object-fit: contain;
  max-width: 100%;
}


/* Utility Classes
======================== */
/* Line Overflow */
.line-1,
.line-2,
.line-3,
.line-4,
.line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-1 {
  -webkit-line-clamp: 1
}

.line-2 {
  -webkit-line-clamp: 2
}

.line-3 {
  -webkit-line-clamp: 3
}

.line-4 {
  -webkit-line-clamp: 4
}

.line-5 {
  -webkit-line-clamp: 5
}

/*text capitalize  */
.capitalize {
  text-transform: capitalize;
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.content-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.content-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.section-padding {
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}

.section-padding-top {
  padding-top: var(--section-spacing);
}

.section-padding-bottom {
  padding-bottom: var(--section-spacing);
}

.section-margin {
  margin-top: var(--section-spacing);
  margin-bottom: var(--section-spacing);
}

.section-margin-top {
  margin-top: var(--section-spacing);
}

.section-margin-bottom {
  margin-bottom: var(--section-spacing);
}


/* === heading section === */
.heading-padding {
  padding-top: var(--heading-spacing);
  padding-bottom: var(--heading-spacing);
}

.heading-padding-top {
  padding-top: var(--heading-spacing);
}

.heading-padding-bottom {
  padding-bottom: var(--heading-spacing);
}

.heading-margin {
  margin-top: var(--heading-spacing);
  margin-bottom: var(--heading-spacing);
}

.heading-margin-top {
  margin-top: var(--heading-spacing);
}

.heading-margin-bottom {
  margin-bottom: var(--heading-spacing);
}


/*  == text wrap ==  */
.text-nowrap {
  text-wrap: nowrap;
}


/* == scroll == */
.scroll-X {
  overflow-x: scroll;
}

.scroll-y {
  overflow-y: scroll;
}

.scroll-xy {
  overflow: scroll;
}


/* == cursor-pointer == */
.cursor-pointer {
  cursor: pointer;
}


/* === blur css===*/
.blurred-content {
  backdrop-filter: blur(3px);

  /* Adjust the blur intensity as needed */
  background-color: rgba(0, 0, 0, 0.3);

  /* Adjust the background color and transparency as needed */
  border-radius: 0px 0px 20px 20px;
}